.error-wrapper {
    padding: 20px;
}

.error-wrapper p {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
}

.error-wrapper li {
    list-style: inside;
}
