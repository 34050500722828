.widget {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    width: 20rem;
    margin: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
}

.icon-container {
    padding: 0.5rem;
}

.text {
    display: flex;
    flex-flow: column;
    padding: 0.5rem;
    text-align: left;
}

.title {
    font-size: 1rem;
    font-weight: bold;
}

.message {
    font-size: 1rem;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid var(--colour-porterbrook-blue);
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
