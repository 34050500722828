.button {
    background-color: transparent;
    color: var(--color-primary-400);
    border-radius: 4px;
    font-size: 1rem;
    border: 0;
    padding: 5px;
}

.button:hover {
    cursor: pointer;
}

.button:hover,
.button:active {
    color: white;
    background-color: var(--color-primary-000);
}

.button span {
    margin: 0 0.5rem;
}

.badge {
    background-color: var(--color-primary-800);
    border-radius: 30px;
    padding: 0.15rem 1.25rem;
    color: #1d1d1d;
}
