.popup_info-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 1rem;
    right: 1rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
    max-width: 80%;
    max-height: 80%;
    opacity: 0.8;
    transition: opacity 300ms ease-in;
    overflow: hidden;
    padding-bottom: 10px;
}
.popup_info-wrapper:hover {
    opacity: 1;
}

.popup_content {
    overflow-y: auto;
    padding: 1rem;
    max-height: 100%;
    font-size: 0.9rem;
}

.content {
    text-align: left;
}

p,
h5,
hr {
    margin: 0;
    padding: 0;
}

hr {
    margin-bottom: 10px;
}

.drag-handle {
    width: 100%;
    height: 40px;
    background-color: var(--color-gray-300);
    cursor: grab;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
}

.drag-handle:active {
    cursor: grabbing;
}

.backdrop-layer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
}
