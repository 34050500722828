.healthbar {
    margin: auto 5px auto 0;
    top: 0;
    left: 10px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    position: absolute;
}

.healthbar-wrapper {
    display: flex;
    column-gap: 5px;
    height: 10px;
}

.step {
    height: 100%;
    width: 10px;
    border-radius: 50%;
    background-color: var(--color-gray-600);
}

.is-loading {
    background-color: var(--color-loading);
    animation: pulse 2s infinite;
}

.is-success {
    background-color: var(--color-success);
}

.is-error {
    background-color: var(--color-fail);
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 var(--color-loading);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px var(--color-loading-alpha);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 var(--color-loading-alpha);
	}
}