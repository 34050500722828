.back {
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    background-color: var(--color-gray-300);
    color: var(--color-gray-800);
    border: none;
  }

.back:hover {
    color: #FFF;
  }