p,
h5,
hr {
    margin: 0;
    padding: 0;
}

th {
    padding: 0.9rem;
    font-size: 0.9rem;
    position: relative;
    min-width: 80px;
    text-wrap: wrap;
    border-top: 0;
    line-height: 1.2rem;
    border: solid rgb(165, 164, 164);
    border-width: 0 1px;
    border-collapse: collapse;
    overflow: hidden;
    height: 60px;
}

.column-buttons {
    color: var(--color-primary-200);
}
