@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.form-control {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.control-group {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    flex-wrap: wrap;
}

.control-group .form-control {
    min-width: 15rem;
    flex: 1;
}

.control-group h2 {
    font-size: 1.2rem;
    margin: 0 0 25px 0;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
    background-color: #ccc;
    color: #292929;
    border-color: #ccc;
    cursor: not-allowed;
}

.form-actions {
    text-align: right;
}

.form-actions button {
    margin-left: 1rem;
}

.invalid input {
    border: 1px solid #b40e0e;
    background-color: #fddddd;
}

.invalid input:focus {
    border-color: #ff8800;
    background-color: #fbe8d2;
}

.error-text {
    color: #b40e0e;
}
