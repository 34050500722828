.title {
    font-size: 1.25rem;
    margin: 0.5rem 0;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.form-control {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

li {
    padding: 5px;
}

.staged-updates-wrapper {
    flex: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.form-actions {
    display: flex;
    justify-content: center;
}
