.table {
    table-layout: auto;
    overflow-x: auto;
    border-collapse: collapse;
    border-bottom: 1px solid var(--colour-porterbrook-blue);
    margin: auto;
    padding-top: 1rem;
    padding-top: 0;
}

.table-scroll {
    height: 100%;
    overflow: auto;
    overflow-anchor: none;
}

.table thead {
    background-color: var(--color-head-columns);
    color: var(--color-primary-200);
    top: 0;
    position: sticky;
    z-index: 2;
}

.table td {
    padding: 0 0.9rem;
    border-top: 0.5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.9rem;
    width: 100%;
    text-align: left;
    max-width: 20vh;
    border: 1px solid rgb(165, 164, 164);
    border-collapse: collapse;
}

.table td.has_action {
    cursor: pointer;
}

.table tbody tr:hover {
    position: relative;
}

.table tbody tr:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 22, 22, 0.253);
    pointer-events: none;
}

.table-column__expandable {
    width: 100%;
}

.isGood {
    background-color: rgba(86, 255, 24, 0.7);
}

.filter-wrapper {
    background-color: white;
    padding: 15px;
    margin: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.active {
    position: relative;
}
.active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 22, 22, 0.253);
}

.isVerified {
    background-color: rgba(86, 255, 24, 0.15);
}

.isStaged {
    background-color: rgba(241, 226, 7, 0.2);
}
