.display {
    width: 13rem;
    text-align: left;
    padding-left: 1rem;
    padding-right: 0.25rem;
    margin: 0;
}

.errors {
    position: fixed;
    bottom: 10px;
    right: 10px;
    top: 100%;
}

.table_filter_wrapper {
    max-width: 105ch;
    margin: 0 auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    display: flex;
    height: calc(100vh - (var(--main-padding) + var(--header-size)));
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 15px;
}

.table-wrapper {
    border-top: 4px solid #999fb5;
    height: calc(100% - (var(--main-padding) + var(--header-size)));
    flex-grow: 1;
    position: relative;
}
