.button {
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif;
    border: none;
    background-color: #318d66;
    color: white;
    padding: 0.5rem 1rem;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    margin: 10px;
    font-size: 0.8rem;
  }
  
  .button:hover,
  .button:active {
    background-color: #179801;
  }
  
  .icon {
    margin-right: 0.5rem; 
  }
  