.table {
    display: table;
    table-layout: auto;
    overflow-x: auto;
    border-collapse: collapse;
    border-bottom: 1px solid var(--colour-porterbrook-blue);
    margin: auto;
    padding-top: 1rem;
    padding-top: 0;
    width: 100%;
}

.table thead {
    background-color: var(--color-head-columns);
    color: var(--color-primary-200);
    top: 0;
    position: sticky;
}

.table td {
    padding: 0.9rem;
    border: 1px solid rgb(165, 164, 164);
    border-collapse: collapse;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table tbody tr:hover {
    background-color: var(--color-gray-200);
}

.table-column__expandable {
    width: 100%;
}

.table-action__button {
    font: inherit;
    background-color: var(--colour-porterbrook-blue);
    color: white;
    border: 1px solid var(--colour-porterbrook-blue);
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.filter-wrapper {
    background-color: white;
    padding: 15px;
    margin: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.table-scroll {
    height: 100%;
    overflow: auto;
    overflow-anchor: none;
}
