.table-wrapper {
    width: 90%;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
}

.table {
    display: block;
    overflow: auto;
    table-layout: auto;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    padding-top: 1rem;
}

.table thead {
    background-color: var(--color-head-columns);
    color: var(--color-primary-200);
}

.table th,
table td {
    padding: 0.5rem;
    border: 1px solid rgb(165, 164, 164);
    border-collapse: collapse;
}

.table td {
    border-top: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table tbody tr:hover {
    background-color: var(--color-gray-200);
}

.table-column__expandable {
    width: 100%;
}

.table-action__button {
    font: inherit;
    background-color: var(--colour-porterbrook-blue);
    color: white;
    border: 1px solid var(--colour-porterbrook-blue);
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.icon-container {
    display: flex;
    justify-content: center;
}

.icon {
    cursor: pointer;
}

.empty {
    text-align: center;
}
