.jobs-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - (var(--main-padding) + var(--header-size)));
}

.table-wrapper {
    width: 100%;
    border-top: 4px solid #999fb5;
    height: calc(100% - (var(--main-padding) + var(--header-size)));
    flex-grow: 1;
    position: relative;
}

.title-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 20px 20px 20px;
}

.title-wrapper h1 {
    padding: 0 10px;
}
