.main {
    height: 100%;
    background-image: url('../assets/XCT_Class170.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: #cccccc;
}

.errors {
    position: fixed;
    bottom: 10px;
    right: 10px;
    top: 100%;
}
