.username {
    margin-top: 1rem;
}

.table-wrapper {
    width: 100%;
}

.table {
    overflow: scroll;
    table-layout: auto;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    padding-top: 1rem;
}

.table thead {
    background-color: var(--colour-porterbrook-blue);
    color: var(--color-primary-200);
    
}

.table th, table td {
    padding: 0.5rem;
    border: 1px solid rgb(165, 164, 164);
    border-collapse: collapse;
      
}

.table td {
    border-top: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table tbody tr:hover {
    background-color: var(--color-gray-200);
}
