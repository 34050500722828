* {
    box-sizing: border-box;
}

:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;

    --colour-porterbrook-blue: #0e3b83;
    --colour-porterbrook-grey: #b2b2b2;

    --colour-dark-blue: #092655;

    --color-gray-100-alpha: rgba(244, 243, 241, 0.7);
    --color-gray-100: #f4f3f1;
    --color-gray-200: #dddbd8;
    --color-gray-300: #ccc9c6;
    --color-gray-400: #aeaba7;
    --color-gray-500: #8a8784;
    --color-gray-600: #656360;
    --color-gray-700: #4b4a47;
    --color-gray-800: #31302e;
    --color-gray-900: #1f1d1b;

    --color-primary-100: #fcf3e1;
    --color-primary-200: #fceccd;
    --color-primary-300: #fae1af;
    --color-primary-400: #fbd997;
    --color-primary-500: #ffd37c;
    --color-primary-600: #f9c762;
    --color-primary-700: #32681e;
    --color-primary-800: #34bbda;
    --color-primary-900: #8ec152;
    --color-primary-000: #449fb3;

    --color-success: rgb(49, 141, 102);
    --color-loading: rgba(255, 165, 0);
    --color-loading-alpha: rgba(255, 165, 0, 0);
    --color-fail: rgb(232, 65, 24);
    --color-head-columns: #353b48;

    --header-size: 4rem;
    --main-padding: 2rem;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    color-scheme: light dark;
    /* color: rgba(255, 255, 255, 0.87); */
    color: var(--colour-porterbrook-blue);
    background-color: var(--color-gray-100);
}

body {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

main {
    margin: var(--header-size) auto 0 0;
    text-align: center;
    padding: var(--main-padding) 0 0 0;
    position: relative;
}

hr {
    border-top: 1.5px solid #e2e2e2;
    border-bottom: none;
    margin: 1rem auto;
    width: 100%;
}
