.success-wrapper {
    padding: 20px;
}

.success-wrapper p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 1.3rem;
}

.success-wrapper li {
    list-style: inside;
}
