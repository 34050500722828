.instruction-heading {
    margin-bottom: 0;
}

.instruction-summary {
    text-align: left;
    
}

.instruction-text {
    text-align: left;
    margin: 5px 20px 20px 20px;
}