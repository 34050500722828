.loading-wrapper {
    display: flex;
    position: absolute;
    background-color: var(--color-gray-100-alpha);
    top: 0;
    left: 0;
    z-index: 3;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-top: 5%;
}
