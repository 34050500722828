.header {
    /* max-width: 60rem; */
    width: 100%;
    height: var(--header-size);
    margin: auto;
    display: flex;
    justify-content: space-between;
    background-color: var(--colour-porterbrook-blue);
    position: fixed;
    top: 0;
    z-index: 9;
  }

  nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    
  }
  
  .list {
    display: flex;
    gap: 1rem;
    padding: 1rem;
  }
  
  .list a {
    text-decoration: none;
    color: var(--color-primary-400);
  }
  
  .list a:hover,
  .list a.active {
    color: var(--color-primary-800);
  }

  .logo-container {
    display: flex;
    height: var(--header-size);
    /* z-index: 0; */
    position: fixed;
    left: 50%; 
    transform: translateX(-50%); 
    
  }

  .logo-container img {
    height: 100%;
  } 

.button {
 background-color: var(--colour-porterbrook-blue);
 color: var(--color-primary-400);
 font-size: 1rem;
 border: none;
 cursor: pointer;
}

.button:hover {
  color: var(--color-primary-800);
  background-color: var(--colour-porterbrook-blue);
}