.widget {
    display: flex;
    background-color: #fff;
    border-radius: 2px;
    width: 20rem;
    height: 8rem;
    margin: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
    justify-content: space-between;
    position: relative;
    bottom: 10px;
    transition: transform 300ms ease-in;
    transform: translateY(0%);
}

.widget:nth-child(1) {
    transform: translateY(-100%);
    animation: opacity 1s linear;
}

.icon-container {
    padding: 0.5rem;
    align-items: self-end;
    height: 2rem;
    display: flex;
    align-items: center;
}

.icon-container:hover {
    cursor: pointer;
}

.text {
    display: flex;
    flex-flow: column;
    padding: 0.5rem;
    text-align: left;
}

.error {
    font-size: 1rem;
    font-weight: bold;
}

.message {
    font-size: 1rem;
}

@keyframes opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
